<template>
  <main>
    <div class="dateschutz-page">
      <div class="datenschutz-banner" v-if="page">
        <div class="grid-container">
          <div class="row">
            <div class="col-x12 col-xxl10 offset-xxl1">
              <div class="datenschutz-banner-title">
                <h2 v-html="page.top_title"></h2>
              </div>
              <div class="datenschutz-banner-logo">
                <img :src="require('@/assets/images/' + banner.ribonImg)" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="datenschutz-content">
        <div class="grid-container" v-if="page">
          <div class="row">
            <div class="col-x12 col-xxl10 offset-xxl1">
              <div class="row d-flex">
                <div class="col-l3 col-s5 col-x12 datenschutz-content-left">
                  <h2>Wichtige Links</h2>
                  <ul>
                    <li v-for="(item, navIndex) in nav " :key="navIndex">
                      <a :href="item.url">{{ item.title }}</a>
                    </li>
                  </ul>
                </div>
                <div class="col-l8 col-s7 col-x12 ">
                  <template v-for="(content, index) in page.content">
                    <template v-if="content._group === 'content'">
                      <div class="datenschutz-content-right" v-html="content.content" :key="'ct_' + index"/>
                    </template>
                    <Accordion v-else-if="content._group === 'accordions'" :items="content.list" :key="'ac_' + index"/>
                    <Security v-else-if="content._group === 'blue_banner'" :blue_banner="content"
                              :key="'sec_' + index"/>
                    <Tabs v-else :tabs="content.tabs" :key="'tab_' + index"/>
                  </template>
                </div>
              </div>
              <div class="data-protection-footer">
                <div class="column">
                  <img :src="require('@/assets/images/amex-blue-logo-line.svg')"/>
                </div>
                <div class="column text-right">
                  <a target="_blank" href="https://www.americanexpress.com/de/legal/impressum.html">Impressum</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Accordion from '@/components/accordion'
import Security from '@/components/security'
import Tabs from '@/components/tabs'
import pagesStore from '@/store/modules/cookiePage'
import dynamicModule from '@/store/utils/dynamicModule'

export default {
  data () {
    return {
      page: null,
      banner: {
        title: 'American Express® <br />EU-Online-Datenschutzerklärung',
        ribonImg: 'ribbon3.png'
      }
    }
  },
  metaInfo () {
    return {
      title: this.page?.meta.title !== '' ? this.page?.meta.title : this.page?.title,
      description: this.page?.meta.description
    }
  },
  mixins: [
    dynamicModule('cookiePage', pagesStore)
  ],
  components: {
    Tabs,
    Security,
    Accordion
  },
  computed: {
    ...mapState('cookiePage', ['nav'])
  },
  methods: {
    ...mapActions('cookiePage', ['loadPage', 'loadHome', 'loadNavigation'])
  },
  mounted () {
    this.loadNavigation()
    if (this.$route.params.slug === undefined) {
      this.loadHome().then(response => {
        this.page = response
      })
    } else {
      this.loadPage(this.$route.params.slug).then(response => {
        this.page = response
      })
    }

    let self = this
    window.addEventListener('click', event => {
      let { target } = event
      while (target && target.tagName !== 'A') target = target.parentNode

      // handle only links that do not reference external resources
      if (target && target.matches("a[href='" + process.env.VUE_APP_PUBLIC_PATH + "cookie-popup']") && target.href) {
        event.preventDefault()
        self.$store.dispatch('ui/changeCookieModal', true)
      }
    })
  },
  beforeDestroy () {
    this.clearPage()
  }
}
</script>
